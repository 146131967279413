// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const listUserData = createAsyncThunk('', async (companyCode) => {
  try {
    const res = await fetch(process.env.REACT_APP_APOLLO_SERVER_URI, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json'},
      body: JSON.stringify({
        query: `
        query ListRoleAssignment($companyCode: String!) {
          listRoleAssignment(companyCode: $companyCode) {
            id
            name
            mobileNo
            email
            location {
              value
              label
            }
            roles {
              value
              label
            }
            companyCode
          }
        }`,
        variables: {
          companyCode
        }
      })
    })
    const response = await res.json()
    return await response.data.listRoleAssignment
  } catch (error) {
    throw new Error(error)
  }
})

export const getAvatarUrl = createAsyncThunk('/', async (userId) => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  try {
    const res = await fetch(process.env.REACT_APP_APOLLO_SERVER_URI, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userData.accessToken}` },
      body: JSON.stringify({
        query: `query Query($userId: String!) {
          getUserAvatar(userId: $userId)
        }`,
        variables: {
          userId
        }
      })
    })
    const response = await res.json()
    return await response.data.getUserAvatar
  } catch (error) {
    throw new Error(error)
  }
})

export const userRolesSlice = createSlice({
  name: 'listUsers',
  initialState: {
    listUser: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(listUserData.fulfilled, (state, action) => {
      state.listUser = action.payload
    })
  }
})

export default userRolesSlice.reducer
